import React from "react";
import Seo from "../../components/seo";

const PrivacyPage = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <Seo />
      <h1>My PrEP</h1>
      <h2>Privacy Policy</h2>
      <h3>If you’re not a My PrEP+ subscriber</h3>
      <p>
        No information is collected. The app send no data to the Internet. The
        history of your doses is stored locally on your phone. No tracker is
        active in the app.
      </p>
      <h3>If you’re a My PrEP+ subscriber</h3>
      <p>
        The only information collected is the history of your My PrEP+
        subscription, through the SDK revenuecat.com, and this in an anonymous
        way. That’s all. No other information is collected. The history of your
        intercourses is stored locally on your phone.
      </p>
      <h3>How to delete my data?</h3>
      <p>
        To delete your data, go to the Settings tab and press "Erase my data",
        or just uninstall the app.
      </p>
    </div>
  );
};

export default PrivacyPage;
